<template>
  <div class="external-transaction-activity">
    <PageTitle title="品項組合" btn="新增" @btnClick="onCreate" />
    <FiltersContainer>
      <el-input
        v-model="search.name"
        clearable
        placeholder="搜尋品項組合名稱"
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <el-input
        v-model="search.equityName"
        clearable
        placeholder="搜尋品項內容"
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <el-select
        v-model="search.hasPlan"
        clearable
        placeholder="是否有訂閱方案選用"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <el-option
          v-for="status in subscribeUseStatusConfig"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </el-select>
    </FiltersContainer>
    <SubscribeCombineTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onActivated, onMounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import SubscribeCombineTable from './components/SubscribeCombineTable.vue'
import { subscribeUseStatusConfig } from '@/config/subscribe'
import { GetSubscribeEquityPack, GetSubscribeEquityPackCount } from '@/api/subscribe'
import { isBoolean } from 'lodash'
export default defineComponent({
  name: 'SubscribeCombineList',
  components: {
    PageTitle,
    FiltersContainer,
    SubscribeCombineTable,
  },
  setup () {
    const router = useRouter()
    const {
      extendData,
      search,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    extendData('search', {
      name: null,
      equityName: null,
      hasPlan: null,
    })

    const onCreate = () => {
      router.push({ name: 'SubscribeCombineEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        sortType: 'DESC',
        sortBy: 'createdAt',
        name: search.name || undefined,
        equityName: search.equityName || undefined,
        hasPlan: isBoolean(search.hasPlan) ? search.hasPlan : undefined,
      }
      await Promise.allSettled([
        fetchData(GetSubscribeEquityPack, payload),
        fetchDataCount(GetSubscribeEquityPackCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    onActivated(() => {
      refresh()
    })
    return {
      search,
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      refresh,
      onCreate,
      subscribeUseStatusConfig,
    }
  },
})
</script>
