<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <p class="text-gray-60 text-sub mb-[20px]">訂閱方案進行中的訂單數量為 0，才能編輯此{{ type }}</p>
    <GrayBlockContainer v-loading="loading">
      <div class="flex flex-col gap-[20px]">
        <div v-for="item in planData" :key="item.name" class="flex flex-col gap-[10px]">
          <span class="text-gray-100 font-bold text-normal">{{ item.name }}</span>
          <div v-for="subItem in item.items" :key="subItem.id" class="flex gap-[20px] pl-[16px] text-gray-80 text-sub">
            <span class="font-medium w-[120px]">{{ subItem.label }}</span>
            <span class="font-normal ml-[120px]">{{ subItem.value }}</span>
          </div>
        </div>
      </div>
    </GrayBlockContainer>
    <span slot="footer" class="dialog-footer">
      <div class>
        <el-button plain @click="goToSubscribePlan()">前往訂閱方案</el-button>
        <el-button type="primary" @click="$emit('close')">
          確認
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { get, map } from 'lodash'
import router from '@/router'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useSubscribe } from '@/use/useSubscribe'

export default defineComponent({
  name: 'EditSubscribeCombineItemDialog',
  components: {
    GrayBlockContainer,
  },
  props: {
    selectItem: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const { previewContract, previewEquityPack } = useSubscribe()
    const planData = ref([])
    const loading = ref(false)
    const dialogTitle = computed(() => `選用「${get(props.selectItem, 'name')}」的訂閱方案`)
    const goToSubscribePlan = () => {
      router.push({
        name: 'SubscribePlanList',
      })
    }
    const getOrderCount = async () => {
      loading.value = true
      const id = get(props.selectItem, 'id')
      let fetchApi = previewContract
      if (props.type === '品項組合') fetchApi = previewEquityPack
      const [res, err] = await fetchApi(id)
      if (err) {
        console.log('err', err)
      }
      planData.value = map(res, (item) => {
        return {
          name: get(item, 'name'),
          items: [
            { label: '進行中的訂單數量', value: get(item, 'orderCount') },
          ],
        }
      })
      loading.value = false
    }
    onMounted(async () => {
      if (props.selectItem) {
        await getOrderCount()
      }
    })
    return {
      dialogTitle,
      goToSubscribePlan,
      planData,
      loading,
    }
  },
})
</script>

<style scoped lang="scss"></style>
